import React, { useEffect, useState } from 'react';
import './landing.css';
import Home from './Home/home';
import logo from '../../assets/images/logo.png';
import Facility from './Facility/facility';
import Booking from './Booking/booking';
import About from './About/about';
import { useNavigate } from 'react-router-dom';
import bars from '../../assets/images/bars.png';
import cross from '../../assets/images/cross.png';
import axios from 'axios';

function Landing({ auth, setAuth }) {
    const [scrollHeight, setScrollHeight] = useState(0);
    const navigate = useNavigate();
    const [navActive, setNavActive] = useState(false);
    const [viewBookings, setViewBookings] = useState(false);
    const [userBookings, setUserBookings] = useState([]);
    const [loading, setLoading] = useState(false);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    useEffect(() => {

        window.addEventListener('scroll', () => {
            setScrollHeight(document.documentElement.scrollTop);
        })

        return () => {
            window.removeEventListener('scroll', () => {
                setScrollHeight(document.documentElement.scrollTop);
            })
        }
    }, []);

    useEffect(() => {
        const fetchUserBookings = async () => {
            if (!viewBookings) {
                return;
            }

            setLoading(true);
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + '/bookings/user-bookings', {
                    booking_info: JSON.stringify({
                        token: auth.token
                    })
                });

                if (response.data.status !== 200) {
                    alert(response.data.message);
                    setLoading(false)
                    return;
                }

                setUserBookings(response.data.bookings);
                setLoading(false);
            } catch (error) {
                alert('An error occurred. Please try again.');
                console.error(error);
                setLoading(false);
            }

            setLoading(false);
        }

        fetchUserBookings();
    }, [viewBookings]);

    const cancelBooking = async (bookingId) => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/bookings/cancel-booking', {
                booking_info: JSON.stringify({
                    token: auth.token,
                    booking_id: bookingId
                })
            });

            if (response.data.status !== 200) {
                alert(response.data.message);
                setLoading(false)
                return;
            }

            setUserBookings(userBookings.filter(booking => booking[0] !== bookingId));
            setLoading(false);
        } catch (error) {
            alert('An error occurred. Please try again.');
            console.error(error);
            setLoading(false);
        }

        setLoading(false);
    }

    return (
        <div className='landing-page'>
            <div className={viewBookings ? 'my-bookings-cont active' : 'my-bookings-cont'}>
                <div className='my-bookings-header'>
                    <img src={cross} onClick={() => setViewBookings(false)} />
                </div>

                <div className='my-bookings-list'>
                    {
                        userBookings.map((booking, index) => (
                            <div key={index} className='user-booking'>
                                <p>Courts: {booking[1].join(", ")}</p>
                                <p>Time: {booking[5]}</p>
                                <p>Duration: {booking[6]}</p>
                                <p>Date: {booking[2]} {monthNames[booking[3] - 1]} {booking[4]}</p>
                                <p>Status: {booking[7]}</p>

                                <div className='user-booking-btn'>
                                    <button onClick={() => cancelBooking(booking[0])}>Cancel</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className='landing-nav'>
                <div className='nav-logo' href='#home'>
                    <img className='nav-logo-img' src={logo} />
                </div>
                <div className='nav-opts'>
                    <a className='nav-opt' style={{ borderBottom: scrollHeight < window.innerHeight ? "2px solid white" : '2px solid #152238' }} href='#home'>Home</a>
                    <a className='nav-opt' style={{ borderBottom: (scrollHeight < window.innerHeight * 2) && (scrollHeight >= window.innerHeight) ? "2px solid white" : '2px solid #152238' }} href='#facility'>Our Facility</a>
                    <a className='nav-opt' style={{ borderBottom: (scrollHeight < window.innerHeight * 3) && (scrollHeight >= window.innerHeight * 2) ? "2px solid white" : '2px solid #152238' }} href='#booking'>Booking</a>
                    <a className='nav-opt' style={{ borderBottom: (scrollHeight < window.innerHeight * 4) && (scrollHeight >= window.innerHeight * 3) ? "2px solid white" : '2px solid #152238' }} href='#about'>Contact</a>
                </div>
                <div className='nav-actns'>
                    {
                        auth.email ?
                            <>
                                {/* <button onClick={() => setViewBookings(true)}>My Bookings</button> */}
                                {/* <button onClick={() => { setAuth({}); sessionStorage.clear() }}>Logout</button> */}
                            </>
                            :
                            <>
                                {/* <button onClick={() => navigate('/login')}>Log In</button> */}
                                {/* <button onClick={() => navigate('/register')}>Register</button> */}
                            </>
                    }
                </div>
            </div>
            <div className={navActive ? 'mobile-landing-nav active' : 'mobile-landing-nav'}>
                <div className='mobile-nav-header'>
                    <div className='mobile-nav-icon'>
                        <img className='mobile-nav-icon-img' onClick={() => setNavActive(!navActive)} src={navActive ? cross : bars} />
                    </div>
                    <div className='mobile-nav-logo'>
                        <img className='mobile-nav-logo-img' src={logo} />
                    </div>
                </div>
                <div className='mobile-nav-body'>
                    <div className='mobile-nav-opt'>
                        <a className='mobile-nav-opt-link' onClick={() => setNavActive(false)} href='#home'>Home</a>
                    </div>
                    <div className='mobile-nav-opt'>
                        <a className='mobile-nav-opt-link' onClick={() => setNavActive(false)} href='#our-facility'>Our Facility</a>
                    </div>
                    <div className='mobile-nav-opt'>
                        <a className='mobile-nav-opt-link' onClick={() => setNavActive(false)} href='#rates'>Rates</a>
                    </div>
                    {/* <div className='mobile-nav-opt'>
                        <a onClick={() => setNavActive(false)} href='#book-now'>Booking</a>
                    </div> */}
                    <div className='mobile-nav-opt'>
                        <a className='mobile-nav-opt-link' onClick={() => setNavActive(false)} href='#about'>Contact</a>
                    </div>
                    {/* {
                        auth.email ?
                            <>
                                <div className='mobile-nav-opt'>
                                    <button onClick={() => { setViewBookings(true); setNavActive(false) }}>My Bookings</button>
                                </div>
                                <div className='mobile-nav-opt'>
                                    <button onClick={() => { setAuth({}); sessionStorage.clear() }}>Logout</button>
                                </div>
                            </>
                            :
                            <>
                                <div className='mobile-nav-opt'>
                                    <button onClick={() => { navigate('/login'); setNavActive(false) }}>Log In</button>
                                </div>
                                <div className='mobile-nav-opt'>
                                    <button onClick={() => { navigate('/register'); setNavActive(false) }}>Register</button>
                                </div>
                            </>
                    } */}
                </div>
            </div>
            <Home />
            <Facility />
            <Booking auth={auth} />
            <About />
        </div>
    );
}

export default Landing;