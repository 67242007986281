import React, { useState, useEffect } from 'react';
import "./facility.css"
import clubCourt from "../../../assets/images/courtImg.jpg"
import proShop from "../../../assets/images/proShopImg.png"
import coaching from "../../../assets/images/coachingImg.jpg"
import leftArrow from "../../../assets/images/leftArrow.png"
import rightArrow from "../../../assets/images/rightArrow.png"


function Facility() {
    const [currCat, setCurrCat] = useState(0);
    const titles = ["Courts", "Pro Shop", "Coaching"];
    const images = [clubCourt, proShop, coaching];

    const descriptions = [
        "Our facility's badminton courts are truly exceptional, offering a professional-grade experience for both training and playing. Imported from Asia, these courts feature a height of 35 feet, providing ample space for high shots and advanced play. The 7mm padded mats ensure a comfortable and safe playing surface, reducing the impact on joints. Additionally, the net stands are designed to be weighted and heavy, ensuring they remain securely in place during intense matches. These top-quality courts make our facility an ideal destination for badminton enthusiasts seeking the best in class infrastructure.",
        "Our pro shop caters to all your badminton needs, offering a convenient destination for players of all levels. Soon featuring Victor and Yonex products, our shop will stock high-quality equipment and accessories, including rackets, shuttlecocks, and apparel. We also provide professional stringing services to keep your racket in top condition. With expert staff available for advice, our pro shop ensures you have the best gear and support for your badminton journey.",
        "We offer top-tier coaching services with high-performance and national level coaches. Our expert coaches provide personalized training programs tailored to improve your skills, whether you're a beginner or an advanced player. With a focus on technique, strategy, and overall fitness, our coaching services are designed to help you reach your full potential on the court. Join us to benefit from the experience and knowledge of our national-level coaches and elevate your badminton game to new heights."
    ]

    return (
        <div className='facility-section' id='facility'>
            <div className='facility-body'>
                <div className='facility-body-ls'>
                    <img className='facility-body-ls-img' src={images[currCat]} />
                </div>
                <div className='facility-body-rs'>
                    <h1 className='facility-body-rs-title'>{titles[currCat]}</h1>
                    <p className='facility-body-rs-desc'>{descriptions[currCat]}</p>
                </div>
            </div>
            <div className='facility-footer'>
                <div className='facility-footer-img'>
                    <h3 className='facility-footer-img-title' onClick={() => setCurrCat(0)}>Courts</h3>
                    <img className='footer-img' onClick={() => setCurrCat(0)} src={clubCourt} />
                </div>
                <div className='facility-footer-img'>
                    <h3 className='facility-footer-img-title' onClick={() => setCurrCat(1)}>Pro Shop</h3>
                    <img className='footer-img' onClick={() => setCurrCat(1)} src={proShop} />
                </div>
                <div className='facility-footer-img'>
                    <h3 className='facility-footer-img-title' onClick={() => setCurrCat(2)}>Coaching</h3>
                    <img className='footer-img' onClick={() => setCurrCat(2)} src={coaching} />
                </div>
            </div>

            <div className='mobile-facility-cont' id='our-facility'>
                <div className='mobile-facility-feature'>
                    <h1 className='mobile-facility-feature-title'>{titles[0]}</h1>
                    <img className='mobile-facility-feature-img' src={images[0]} />
                    <p className='mobile-facility-feature-desc'>{descriptions[0]}</p>
                </div>
                <div className='mobile-facility-feature'>
                    <h1 className='mobile-facility-feature-title'>{titles[1]}</h1>
                    <img className='mobile-facility-feature-img' src={images[1]} />
                    <p className='mobile-facility-feature-desc'>{descriptions[1]}</p>
                </div>
                <div className='mobile-facility-feature'>
                    <h1 className='mobile-facility-feature-title'>{titles[2]}</h1>
                    <img className='mobile-facility-feature-img' src={images[2]} />
                    <p className='mobile-facility-feature-desc'>{descriptions[2]}</p>
                </div>
            </div>
        </div>
    );
}

export default Facility;