import React from 'react'
import "./register.css"
import backArrow from "../../assets/images/arrow.png"
import home from "../../assets/images/home.png"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Register({ setAuth }) {
  const navigate = useNavigate();
  const days = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const handleRegistration = async (e) => {
    e.preventDefault();
    console.log('Registration form submitted');

    if (e.target.elements['password'].value !== e.target.elements['confirm-password'].value) {
      alert('Passwords do not match');
      return;
    }

    if (Number(e.target.elements['dob-month'].value) > 12 || Number(e.target.elements['dob-month'].value) < 1) {
      alert('Invalid month');
      return;
    }

    if (Number(e.target.elements['dob-date'].value) > days[Number(e.target.elements["dob-month"].value) - 1] || Number(e.target.elements['dob-date'].value) < 1) {
      alert('Invalid date');
      return;
    }

    const numberPattern = /^\d{3}-\d{3}-\d{4}$/;

    if (!numberPattern.test(e.target.elements['phone-number'].value)) {
      alert('Invalid phone number');
      return;
    }

    if (!numberPattern.test(e.target.elements['e-contact-phone'].value)) {
      alert('Invalid emergency contact phone number');
      return;
    }

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/athletes/register', {
        user_info: JSON.stringify({
          name: e.target.elements['full-name'].value,
          email: e.target.elements['email'].value,
          phone_num: e.target.elements['phone-number'].value,
          ec_name: e.target.elements['e-full-name'].value,
          ec_num: e.target.elements['e-contact-phone'].value,
          ec_email: e.target.elements['e-contact-email'].value,
          ec_relation: e.target.elements['e-contact-relation'].value,
          password: e.target.elements['password'].value,
          dob: e.target.elements['dob-date'].value + '-' + e.target.elements['dob-month'].value + '-' + e.target.elements['dob-year'].value,
          consent: e.target.elements['e-sign'].value,
          address: e.target.elements['address'].value,
          postal: e.target.elements['postal-code'].value,
          province: e.target.elements['province'].value,
          country: e.target.elements['country'].value
        })
      });

      if (response.data.status === 200) {
        alert('Registration successful!');
        sessionStorage.setItem('hymus_athlete', JSON.stringify(response.data.athlete));
        setAuth(response.data.athlete);
        navigate('/');
      } else {
        alert('An error occurred. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
      console.error(error);
    }
  }

  return (
    <div className='registration-page'>
      <div className='registration-form'>
        <img className='login-img' onClick={() => navigate('/login')} src={backArrow} alt='Login' />
        <img className='home-img' onClick={() => navigate('/')} src={home} alt='Home' />
        <h1>Athlete Registration Form</h1>
        <form onSubmit={handleRegistration}>
          <div className='form-ls'>

            <div className='form-title'>
              <h2>Athlete Information</h2>
            </div>

            <div className='form-group'>
              <label for='full-name'>Full Name: *</label>
              <input placeholder='John Doe' required type='text' id='full-name' name='full-name' />
            </div>
            <div className='form-group'>
              <label for='email'>Email: *</label>
              <input placeholder='john.doe@domain.com' required type='email' id='email' name='email' />
            </div>
            <div className='form-group'>
              <label for='phone-number'>Phone Number: *</label>
              <input placeholder='123-456-7890' required type='tel' id='phone-number' name='phone-number' />
            </div>
            <div className='form-group'>
              <label for='dob'>Date of Birth: *</label>
              <div className='bday-inputs'>
                <input required type='number' id='dob-date' name='dob-date' placeholder='DD' />
                <input required type='number' id='dob-month' name='dob-month' placeholder='MM' />
                <input required type='number' id='dob-year' name='dob-year' placeholder='YYYY' />
              </div>
            </div>

            <div className='form-title'>
              <h2>Emergency/Guardian Contact</h2>
            </div>

            <div className='form-group'>
              <label for='e-full-name'>Full Name: *</label>
              <input placeholder='Jane Doe' required type='text' id='e-full-name' name='e-full-name' />
            </div>
            <div className='form-group'>
              <label for='e-contact-email'>Emergency Contact Email: *</label>
              <input placeholder='jane.doe@domain.com' required type='email' id='e-contact-email' name='e-contact-email' />
            </div>
            <div className='form-group'>
              <label for='e-contact-phone'>Emergency Contact Phone Number: *</label>
              <input placeholder='123-456-7890' required type='tel' id='e-contact-phone' name='e-contact-phone' />
            </div>
            <div className='form-group'>
              <label for='e-contact-relation'>Emergency Contact Relation: *</label>
              <input placeholder='Relation' required type='text' id='e-contact-relation' name='e-contact-relation' />
            </div>

            <div className='form-title'>
              <h2>Account</h2>
            </div>

            <div className='form-group'>
              <label for='password'>Password: *</label>
              <input required type='password' id='password' name='password' />
            </div>
            <div className='form-group'>
              <label for='confirm-password'>Confirm Password: *</label>
              <input required type='password' id='confirm-password' name='confirm-password' />
            </div>
          </div>

          <div className='form-rs'>
            <div className='form-title'>
              <h2>Address</h2>
            </div>

            <div className='form-group'>
              <label for='address'>Address Line:</label>
              <input placeholder='1 Park Lane' type='text' id='address' name='address' />
            </div>
            <div className='form-group'>
              <label for='province'>Province:</label>
              <input placeholder='Ontario' type='text' id='province' name='province' />
            </div>
            <div className='form-group'>
              <label for='country'>Country:</label>
              <input placeholder='Canada' type='text' id='country' name='country' />
            </div>
            <div className='form-group'>
              <label for='postal-code'>Postal Code:</label>
              <input placeholder='M1L 2E1' type='text' id='postal-code' name='postal-code' />
            </div>

            <div className='form-title'>
              <h2>Waiver and Release Liability</h2>
            </div>

            <div className='waiver-cont'>
              <p>I assume full responsibility for any bodily injury that may occur as a result of facility activities. I also knowingly and freely assume all such risk, known and unknown, even if arising from the releasees or others and assume full responsibility for my participation.
                I acknowledge that Hymus Sports is not liable for any loss or damage to personal belongings. I hereby confirm my intent to sign this document electronically and acknowledge that my data will be stored electronically for record-keeping purposes.</p>
            </div>

            <div className='form-group'>
              <label for='e-sign'>Member/Guardian E-Signature: *</label>
              <label>(Must be signed by Guardian if athlete is not a legal adult)</label>
              <input placeholder='Full Name' required type='text' id='e-sign' name='e-sign' />
            </div>

            <div className='form-group'>
              <button type='submit'>Complete Registration</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
