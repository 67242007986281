import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './components/Landing/landing.js';
import Login from './components/Auth/login.js';
import Register from './components/Auth/register.js';
import { useEffect, useState } from 'react';
import Admin from './components/Admin/admin.js';

function App() {
  const [auth, setAuth] = useState({})

  useEffect(() => {
    if (sessionStorage.getItem('hymus_athlete')) {
      setAuth(JSON.parse(sessionStorage.getItem('hymus_athlete')))
    }
  }, [])

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Landing auth={auth} setAuth={setAuth} />} />
          <Route path="/login" element={<Login setAuth={setAuth}/>} />
          <Route path="/register" element={<Register setAuth={setAuth}/>} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
