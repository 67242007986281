import React, { useEffect, useState } from 'react';
import './booking.css';
import rightArrow from '../../../assets/images/rightArrow.png';
import leftArrow from '../../../assets/images/leftArrow.png';
import court1 from '../../../assets/images/court1.png';
import court2 from '../../../assets/images/court2.png';
import court3 from '../../../assets/images/court3.png';
import court4 from '../../../assets/images/court4.png';
import court5 from '../../../assets/images/court5.png';
import court6 from '../../../assets/images/court6.png';
import court7 from '../../../assets/images/court7.png';
import court8 from '../../../assets/images/court8.png';
import court9 from '../../../assets/images/court9.png';
import court10 from '../../../assets/images/court10.png';
import court11 from '../../../assets/images/court11.png';
import court12 from '../../../assets/images/court12.png';
import compass from "../../../assets/images/compass.png";
import Select from 'react-select';
import axios from 'axios';

function Booking({ auth }) {
    const [month, setMonth] = useState(new Date().getMonth());
    const [day, setDay] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [timeSlots, setTimeSlots] = useState([]);

    const startTimes = [
        { "timeNum": 6, "timeStr": "6:00 AM" }, { "timeNum": 6.5, "timeStr": "6:30 AM" }, { "timeNum": 7, "timeStr": "7:00 AM" }, { "timeNum": 7.5, "timeStr": "7:30 AM" }, { "timeNum": 8, "timeStr": "8:00 AM" }, { "timeNum": 8.5, "timeStr": "8:30 AM" }, { "timeNum": 9, "timeStr": "9:00 AM" }, { "timeNum": 9.5, "timeStr": "9:30 AM" }, { "timeNum": 10, "timeStr": "10:00 AM" }, { "timeNum": 10.5, "timeStr": "10:30 AM" }, { "timeNum": 11, "timeStr": "11:00 AM" }, { "timeNum": 11.5, "timeStr": "11:30 AM" }, { "timeNum": 12, "timeStr": "12:00 PM" }, { "timeNum": 12.5, "timeStr": "12:30 PM" },
        { "timeNum": 13, "timeStr": "1:00 PM" }, { "timeNum": 13.5, "timeStr": "1:30 PM" }, { "timeNum": 14, "timeStr": "2:00 PM" }, { "timeNum": 14.5, "timeStr": "2:30 PM" }, { "timeNum": 15, "timeStr": "3:00 PM" }, { "timeNum": 15.5, "timeStr": "3:30 PM" }, { "timeNum": 16, "timeStr": "4:00 PM" }, { "timeNum": 16.5, "timeStr": "4:30 PM" }, { "timeNum": 17, "timeStr": "5:00 PM" }, { "timeNum": 17.5, "timeStr": "5:30 PM" }, { "timeNum": 18, "timeStr": "6:00 PM" }, { "timeNum": 18.5, "timeStr": "6:30 PM" }, { "timeNum": 19, "timeStr": "7:00 PM" }, { "timeNum": 19.5, "timeStr": "7:30 PM" },
        { "timeNum": 20, "timeStr": "8:00 PM" }, { "timeNum": 20.5, "timeStr": "8:30 PM" }, { "timeNum": 21, "timeStr": "9:00 PM" }, { "timeNum": 21.5, "timeStr": "9:30 PM" }, { "timeNum": 22, "timeStr": "10:00 PM" }, { "timeNum": 22.5, "timeStr": "10:30 PM" }, { "timeNum": 23, "timeStr": "11:00 PM" }, { "timeNum": 23.5, "timeStr": "11:30 PM" }, { "timeNum": 24, "timeStr": "12:00 AM" }, { "timeNum": 24.5, "timeStr": "12:30 AM" }
    ];
    const [currStart, setCurrStart] = useState(6);
    const [currDuration, setCurrDuration] = useState(0.5);
    const [selectedCourts, setSelectedCourts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(currStart)
        console.log(currDuration)
    }, [currDuration, currStart]);


    useEffect(() => {
        console.log(timeSlots);
    }, [timeSlots]);

    const getAvailableTimes = async () => {
        if (day === null) {
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/bookings/retrieve', {
                booking_info: JSON.stringify({
                    date: day,
                    month: month + 1,
                    year: year,
                    token: auth.token ? auth.token : "non-member"
                })
            });

            if (response.data.status !== 200) {
                alert(response.data.message);
                return;
            }

            const slots = [];

            for (let booking of response.data.bookings) {
                const startTime = booking[4];
                const duration = booking[5];

                const sections = duration / 0.5;

                for (let i = 0; i < sections; i++) {
                    for (let court of booking[0]) {
                        slots.push(
                            {
                                date: booking[1],
                                month: booking[2],
                                year: booking[3],
                                time: startTime + (i * 0.5),
                                court: court
                            }
                        )
                    }
                }
            }

            setTimeSlots(slots);
            setLoading(false);
        } catch (error) {
            alert('An error occurred. Please try again.');
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getAvailableTimes();
    }, [day]);

    const generateDays = () => {
        let days = [];
        const dayCount = new Date(year, month + 1, 0).getDate();

        if (month === new Date().getMonth() && year === new Date().getFullYear()) {
            for (let i = new Date().getDate(); i <= dayCount; i++) {
                days.push(
                    <div className='calendar-day'>
                        <h1 onClick={() => setDay(i)}>&nbsp;{i}&nbsp;</h1>
                    </div>
                );
            }

            return days;
        }

        for (let i = 1; i <= dayCount; i++) {
            days.push(
                <div className='calendar-day'>
                    <h1 onClick={() => setDay(i)}>&nbsp;{i}&nbsp;</h1>
                </div>
            );
        }

        return days;
    }

    const nextMonth = () => {
        const newMonth = month + 1;
        const newDate = new Date(year, newMonth);

        setMonth(newDate.getMonth());
        setYear(newDate.getFullYear());
    }

    const prevMonth = () => {
        if (month === new Date().getMonth() && year === new Date().getFullYear()) {
            return;
        }

        const newMonth = month - 1;
        const newDate = new Date(year, newMonth);

        setMonth(newDate.getMonth());
        setYear(newDate.getFullYear());
    }

    const handleDuration = () => {
        let hourLengths = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19];
        let hours = []

        const hourCount = startTimes.length - startTimes.findIndex(time => time.timeNum === currStart);

        for (let i = 1; i <= hourCount; i++) {
            hours.push(
                <option value={hourLengths[i - 1]}>{hourLengths[i - 1] + " Hours"}</option>
            )
        }

        return hours;
    }

    const checkCourtConflicts = () => {
        const currSlots = [];
        const sections = currDuration / 0.5;

        for (let i = 0; i < sections; i++) {
            currSlots.push(
                {
                    date: day,
                    month: month + 1,
                    year: year,
                    time: currStart + (i * 0.5)
                }
            )
        }

        const takenCourts = [];

        for (let slot of currSlots) {
            const conflictTimes = timeSlots.filter(timeSlot => timeSlot.date === slot.date && timeSlot.month === slot.month && timeSlot.year === slot.year && timeSlot.time === slot.time);

            for (let conflict of conflictTimes) {
                if (!takenCourts.includes(conflict.court)) {
                    takenCourts.push(conflict.court);
                }
            }
        }

        return takenCourts;
    }

    const handleSelectedCourts = (court) => {
        if (selectedCourts.includes(court)) {
            setSelectedCourts(selectedCourts.filter(selectedCourt => selectedCourt !== court));
        } else {
            setSelectedCourts([...selectedCourts, court]);
        }
    }

    const handleDisplayCourts = () => {
        if (!loading) { //if not laoding
            let courts = [];
            const courtImages = [court1, court2, court3, court4, court5, court6, court7, court8, court9, court10, court11, court12];
            const takenCourts = checkCourtConflicts();
            console.log(selectedCourts)

            for (let i = 0; i < 12; i++) {
                if (takenCourts.includes(i + 1)) {
                    courts.push(
                        <div className={'court-cont-' + (i + 1)} style={{ opacity: 0.5 }}>
                            <img src={courtImages[i]} />
                        </div>
                    );
                } else {
                    courts.push(
                        <div onClick={() => handleSelectedCourts(i + 1)} className={selectedCourts.includes(i + 1) ? 'court-cont-' + (i + 1) + ' active' : 'court-cont-' + (i + 1)}>
                            <img src={courtImages[i]} />
                        </div>
                    );
                }
            }

            return courts;
        }
    }

    const addBooking = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            if (selectedCourts.length === 0) {
                alert('Please select a court.');
                setLoading(false);
                return;
            }

            const bookingData = {
                court_nums: selectedCourts,
                date_num: day,
                month_num: month + 1,
                year_num: year,
                time_num: currStart,
                duration: currDuration,
                type: 'web',
                email: e.target.elements["email"].value,
                number: auth.phone_num ? auth.phone_num : null,
                status: 'tentative',
                name: e.target.elements["name"].value,
                notes: null
            }

            const response = await axios.post(process.env.REACT_APP_API_URL + '/bookings/web-add', {
                "booking_info": JSON.stringify({
                    bookings: [bookingData],
                    token: auth.token ? auth.token : "non-member"
                })
            });

            if (response.data.status !== 200) {
                alert(response.data.message);
                setLoading(false);
                return;
            }

            alert('Booking Successful');
        } catch (error) {
            alert('An error occurred. Please try again.');
            console.error(error);
        }

        setLoading(false);
        handleBack();
    }

    const handleBack = () => {
        setDay(null);
        setCurrDuration(0.5);
        setCurrStart(6);
        setSelectedCourts([]);
    }

    const handleMobileOptions = () => {
        let courts = [];
        const takenCourts = checkCourtConflicts();
        // const currOptions = [{ value: "1", label: "Court 1" }, { value: "2", label: "Court 2" }, { value: "3", label: "Court 3" }, { value: "4", label: "Court 4" }, { value: "5", label: "Court 5" }, { value: "6", label: "Court 6" }, { value: "7", label: "Court 7" }, { value: "8", label: "Court 8" }, { value: "9", label: "Court 9" }, { value: "10", label: "Court 10" }, { value: "11", label: "Court 11" }, { value: "12", label: "Court 12" }]

        for (let i = 0; i < 12; i++) {
            if (takenCourts.includes(i + 1)) {
                courts.push({ value: i + 1, label: "Court " + (i + 1), isDisabled: true })
            } else {
                courts.push({ value: i + 1, label: "Court " + (i + 1) })
            }
        }

        return courts;
    }

    const handleMobileCalendar = () => {
        if (day !== null) {
            return (
                <div className='booking-info-cont'>
                    <form onSubmit={addBooking}>
                        <div className='booking-inputs'>
                            <div className='booking-inputs-title'>
                                <h3>{months[month] + " " + day + ", " + year}</h3>
                            </div>

                            <div className='booking-inputs-info'>
                                <label>Name:</label>
                                <input defaultValue={auth.name ? auth.name : null} required type='text' name='name' />

                                <label>Email Address:</label>
                                <input defaultValue={auth.email ? auth.email : null} required type='email' placeholder='example@address.com' name='email' />

                                <label>Start Time:</label>
                                <select onChange={(e) => setCurrStart(Number(e.target.value))} name='starttime'>
                                    {
                                        startTimes.map((time, index) => (
                                            <option value={time.timeNum} key={index}>{time.timeStr}</option>
                                        ))
                                    }
                                </select>

                                <label>Duration:</label>
                                <select onChange={(e) => setCurrDuration(Number(e.target.value))} name='duration'>
                                    {handleDuration()}
                                </select>

                                <label>Courts:</label>
                                <Select
                                    styles={{
                                        valueContainer: (provided) => ({
                                            ...provided,
                                            display: 'flex',
                                            flexWrap: 'nowrap',
                                            overflowX: 'auto',
                                            overflowY: 'hidden',
                                            maxWidth: '100%',
                                        }),
                                        multiValue: (provided) => ({
                                            ...provided,
                                            flexShrink: 0,
                                        }),
                                        menuList: (provided) => ({
                                            ...provided,
                                            maxHeight: 200, // This also affects the scrollable area
                                        }),
                                    }}
                                    onChange={(value) => setSelectedCourts(value.map(court => court.value))}    //onChange={(value) => setSelectedCourts(value.map(court => court.value))}
                                    isMulti
                                    options={loading ? [] : handleMobileOptions()}
                                />
                            </div>
                        </div>
                        <div className='booking-info-btns'>
                            <button disabled={loading} type='button' onClick={handleBack}>Back</button>
                            <button disabled={loading} type='submit'>Confirm</button>
                        </div>
                    </form>
                </div>
            )
        }

        return (
            <div className='calendar-cont'>
                <h1>Ready to play?</h1>
                <div className='calendar-month'>
                    <img className='calendar-arrow' onClick={prevMonth} src={leftArrow} />
                    <h3 className='calendar-month-text'>{months[month] + " " + year}</h3>
                    <img className='calendar-arrow' onClick={nextMonth} src={rightArrow} />
                </div>
                <div className='calendar-days'>
                    {generateDays()}
                </div>
            </div>
        )
    }

    const handleBookingInfo = () => {
        if (day === null) {
            return (
                <div className='calendar-cont'>
                    <div className='calendar-month'>
                        <img onClick={prevMonth} src={leftArrow} />
                        <h3>{months[month] + " " + year}</h3>
                        <img onClick={nextMonth} src={rightArrow} />
                    </div>
                    <div className='calendar-days'>
                        {generateDays()}
                    </div>
                </div>
            );
        }

        return (
            <div className='booking-info-cont'>
                <form onSubmit={addBooking}>
                    <div className='booking-inputs'>
                        <div className='booking-inputs-title'>
                            <h3>{months[month] + " " + day + ", " + year}</h3>
                        </div>

                        <div className='booking-inputs-info'>
                            <label>Name:</label>
                            <input defaultValue={auth.name ? auth.name : null} required type='text' name='name' />

                            <label>Email Address:</label>
                            <input defaultValue={auth.email ? auth.email : null} required type='email' placeholder='example@address.com' name='email' />

                            <label>Start Time:</label>
                            <select onChange={(e) => setCurrStart(Number(e.target.value))} name='starttime'>
                                {
                                    startTimes.map((time, index) => (
                                        <option value={time.timeNum} key={index}>{time.timeStr}</option>
                                    ))
                                }
                            </select>

                            <label>Duration:</label>
                            <select onChange={(e) => setCurrDuration(Number(e.target.value))} name='duration'>
                                {handleDuration()}
                            </select>
                        </div>
                    </div>
                    <div className='booking-info-btns'>
                        <button type='button' onClick={handleBack}>Back</button>
                        <button type='submit'>Confirm</button>
                    </div>
                </form>
            </div>
        );
    }

    const handlePricingInfo = () => {
        if (day === null) {
            return (
                <div className='pricing-cont'>
                    <div className='pricing-title'>
                        <h3 className='pricing-title-text'>Rates</h3>
                    </div>
                    <div className='pricing-info'>
                        <h4 className='pricing-info-title'>Drop In</h4>
                        <p className='pricing-info-text'>
                            <span>Mon-Fri: 6AM-5PM</span>
                            <span>$5.00 / Person</span>
                        </p>
                        <p className='pricing-info-text'>
                            <span>Mon-Fri: 5PM-1AM, Weekends/Holidays</span>
                            <span>$10.00 / Person</span>
                        </p>
                        <h4 className='pricing-info-title'>Court Rental</h4>
                        <p className='pricing-info-text'>
                            <span>Mon-Fri: 6AM-5PM</span>
                            <span>$10.00 / Hour</span>
                        </p>
                        <p className='pricing-info-text'>
                            <span>Mon-Fri: 5PM-1AM, Weekends/Holidays</span>
                            <span>$20.00 / Hour</span>
                        </p>
                        <h4 className='pricing-info-title'>Equipment Rental</h4>
                        <p className='pricing-info-text'><span>Racket</span><span>$3.00 / Racket</span></p>
                        <p className='pricing-info-text'><span>Court Shoes</span><span>$3.00 / Pair</span></p>
                    </div>
                </div>
            )
        } else if (day !== null) {
            return (
                <div className='courts-display-cont'>
                    <div className='courts-display-title'>
                        <h3>Available Courts</h3>
                        <img src={compass} />
                    </div>
                    {
                        !loading ?
                            <div className='courts-display'>
                                {handleDisplayCourts()}
                            </div>
                            :
                            <div className='loading-cont'>
                                <h1>Loading courts...</h1>
                            </div>
                    }
                </div>
            );
        }
    }

    return (
        <>
            <div className='booking-section' id='booking'>
                <div className='booking-title'>
                    <h1>Ready to play?</h1>
                </div>
                <div className='booking-body'>
                    <div className='booking-ls'>
                        {handleBookingInfo()}
                    </div>
                    <div className='booking-rs'>
                        {handlePricingInfo()}
                    </div>
                </div>
            </div>

            <div className='rates-section' id='rates'>
                <div className='pricing-cont'>
                    <div className='pricing-title'>
                        <h3 className='pricing-title-text'>Rates</h3>
                    </div>
                    <div className='pricing-info'>
                        <h4 className='pricing-info-title'>Drop In</h4>
                        <p className='pricing-info-text'>
                            <span>Mon-Fri: 6AM-5PM</span>
                            <span>$5.00 / Person</span>
                        </p>
                        <p className='pricing-info-text'>
                            <span>Mon-Fri: 5PM-1AM, Weekends/Holidays</span>
                            <span>$10.00 / Person</span>
                        </p>
                        <h4 className='pricing-info-title'>Court Rental</h4>
                        <p className='pricing-info-text'>
                            <span>Mon-Fri: 6AM-5PM</span>
                            <span>$10.00 / Hour</span>
                        </p>
                        <p className='pricing-info-text'>
                            <span>Mon-Fri: 5PM-1AM, Weekends/Holidays</span>
                            <span>$20.00 / Hour</span>
                        </p>
                        <h4 className='pricing-info-title'>Equipment Rental</h4>
                        <p className='pricing-info-text'><span>Racket</span><span>$3.00 / Racket</span></p>
                        <p className='pricing-info-text'><span>Court Shoes</span><span>$3.00 / Pair</span></p>
                    </div>
                </div>
            </div>

            <div className='calendar-section' id='book-now'>
                {handleMobileCalendar()}
            </div>
        </>
    );
}

export default Booking;