import React from 'react';
import "./home.css"
import logo from "../../../assets/images/logo.png"
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();
    return (
        <div className='home-section' id='home'>
            <div className='home-ls'>
                <div className='home-title'>
                    <h1 className='home-title-text'>Welcome to <br/> Hymus Sports!</h1>
                </div>
                <div className='home-txt'>
                    <p className='home-txt-content'>
                        Over the past several years, many sports facilities have been built to promote health and improve life quality. These venues are crucial for encouraging physical activity and recreational sports.
                        <br/><br/>
                        Sports complexes are becoming key community centers and economic contributors, offering year-round sports and serving as social hubs.
                        <br/><br/>
                        Recognizing this, Hymus Sports has opened Scarborough, Ontario's largest private indoor sports complex, focusing on badminton. We cater to all ages and genders from nearby areas.
                    </p>
                </div>
                <div className='home-btn'>
                    {/* <a href='#booking'>Book Now</a> */}
                    {/* <a href='/register'>Join Us</a> */}
                </div>
            </div>
        </div>
    );
}

export default Home;