import React, { useEffect, useState } from 'react';
import "./admin.css";
import axios from 'axios';
import Select from 'react-select';

function Admin() {
    const [auth, setAuth] = useState({})
    const [bookingOpt, setBookingOpt] = useState(0);
    const [bookingCourts, setBookingCourts] = useState([]);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [takenSlots, setTakenSlots] = useState([]);
    const [bookings, setBookings] = useState([]);
    const currDate = new Date();
    const [bookingMonth, setBookingMonth] = useState(currDate.getMonth());
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const [currMonth, setCurrMonth] = useState(currDate.getMonth());
    const [currYear, setCurrYear] = useState(currDate.getFullYear());
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [bookingYear, setBookingYear] = useState(currDate.getFullYear());
    const [currStart, setCurrStart] = useState(6);
    const startTimes = [
        { "timeNum": 6, "timeStr": "6:00 AM" }, { "timeNum": 6.5, "timeStr": "6:30 AM" }, { "timeNum": 7, "timeStr": "7:00 AM" }, { "timeNum": 7.5, "timeStr": "7:30 AM" }, { "timeNum": 8, "timeStr": "8:00 AM" }, { "timeNum": 8.5, "timeStr": "8:30 AM" }, { "timeNum": 9, "timeStr": "9:00 AM" }, { "timeNum": 9.5, "timeStr": "9:30 AM" }, { "timeNum": 10, "timeStr": "10:00 AM" }, { "timeNum": 10.5, "timeStr": "10:30 AM" }, { "timeNum": 11, "timeStr": "11:00 AM" }, { "timeNum": 11.5, "timeStr": "11:30 AM" }, { "timeNum": 12, "timeStr": "12:00 PM" }, { "timeNum": 12.5, "timeStr": "12:30 PM" },
        { "timeNum": 13, "timeStr": "1:00 PM" }, { "timeNum": 13.5, "timeStr": "1:30 PM" }, { "timeNum": 14, "timeStr": "2:00 PM" }, { "timeNum": 14.5, "timeStr": "2:30 PM" }, { "timeNum": 15, "timeStr": "3:00 PM" }, { "timeNum": 15.5, "timeStr": "3:30 PM" }, { "timeNum": 16, "timeStr": "4:00 PM" }, { "timeNum": 16.5, "timeStr": "4:30 PM" }, { "timeNum": 17, "timeStr": "5:00 PM" }, { "timeNum": 17.5, "timeStr": "5:30 PM" }, { "timeNum": 18, "timeStr": "6:00 PM" }, { "timeNum": 18.5, "timeStr": "6:30 PM" }, { "timeNum": 19, "timeStr": "7:00 PM" }, { "timeNum": 19.5, "timeStr": "7:30 PM" },
        { "timeNum": 20, "timeStr": "8:00 PM" }, { "timeNum": 20.5, "timeStr": "8:30 PM" }, { "timeNum": 21, "timeStr": "9:00 PM" }, { "timeNum": 21.5, "timeStr": "9:30 PM" }, { "timeNum": 22, "timeStr": "10:00 PM" }, { "timeNum": 22.5, "timeStr": "10:30 PM" }, { "timeNum": 23, "timeStr": "11:00 PM" }, { "timeNum": 23.5, "timeStr": "11:30 PM" }, { "timeNum": 24, "timeStr": "12:00 AM" }, { "timeNum": 24.5, "timeStr": "12:30 AM" }
    ];
    const durations = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19];

    const [currDuration, setCurrDuration] = useState(0.5);
    const [bookingDay, setBookingDay] = useState(currDate.getDate());
    const [endBookingMonth, setEndBookingMonth] = useState(currDate.getMonth());
    const [endBookingYear, setEndBookingYear] = useState(currDate.getFullYear());

    const allCourts = [{ value: 1, label: "Court 1" }, { value: 2, label: "Court 2" }, { value: 3, label: "Court 3" }, { value: 4, label: "Court 4" }, { value: 5, label: "Court 5" }, { value: 6, label: "Court 6" }, { value: 7, label: "Court 7" }, { value: 8, label: "Court 8" }, { value: 9, label: "Court 9" }, { value: 10, label: "Court 10" }, { value: 11, label: "Court 11" }, { value: 12, label: "Court 12" }]
    const [availableCourts, setAvailableCourts] = useState([{ value: 1, label: "Court 1" }, { value: 2, label: "Court 2" }, { value: 3, label: "Court 3" }, { value: 4, label: "Court 4" }, { value: 5, label: "Court 5" }, { value: 6, label: "Court 6" }, { value: 7, label: "Court 7" }, { value: 8, label: "Court 8" }, { value: 9, label: "Court 9" }, { value: 10, label: "Court 10" }, { value: 11, label: "Court 11" }, { value: 12, label: "Court 12" }]);

    const days = [
        Array.from({ length: 31 }, (_, i) => i + 1),
        Array.from({ length: 29 }, (_, i) => i + 1),
        Array.from({ length: 31 }, (_, i) => i + 1),
        Array.from({ length: 30 }, (_, i) => i + 1),
        Array.from({ length: 31 }, (_, i) => i + 1),
        Array.from({ length: 30 }, (_, i) => i + 1),
        Array.from({ length: 31 }, (_, i) => i + 1),
        Array.from({ length: 31 }, (_, i) => i + 1),
        Array.from({ length: 30 }, (_, i) => i + 1),
        Array.from({ length: 31 }, (_, i) => i + 1),
        Array.from({ length: 30 }, (_, i) => i + 1),
        Array.from({ length: 31 }, (_, i) => i + 1)
    ];

    useEffect(() => {
        /**
         * Create the taken slots for the schedule
         * The logic is as follows:
         * 1. Loop through the bookings
         * 2. For each booking, get the start time and duration
         * 3. Calculate the number of sections for the duration
         * 4. Loop through the sections and create a slot for each court
         * 5. Add the slot to the slots array
         * 
         * @returns {void}
         */
        const createTakenSlots = () => {
            const slots = [];

            for (let booking of bookings) {
                const startTime = booking[5];
                const duration = booking[6];

                const sections = duration / 0.5;

                for (let i = 0; i < sections; i++) {
                    for (let court of booking[1]) {
                        slots.push(
                            {
                                id: booking[0],
                                date: booking[2],
                                month: booking[3],
                                year: booking[4],
                                time: startTime + (i * 0.5),
                                status: booking[10],
                                court: court,
                                name: booking[11],
                                email: booking[8],
                                number: booking[9]
                            }
                        )
                    }
                }
            }

            setTakenSlots(slots);
        }

        createTakenSlots();
    }, [bookings]);

    useEffect(() => {
        const sortTakenCourts = () => {
            // id: booking[0],
            // date: booking[2],
            // month: booking[3],
            // year: booking[4],
            // time: startTime + (i * 0.5),
            // status: booking[10],
            // court: court,
            // name: booking[11],
            // email: booking[8],
            // number: booking[9]

            let slotTimes = [];

            let sections = currDuration / 0.5;

            for (let i = 0; i < sections; i++) {
                slotTimes.push(currStart + (i * 0.5))
            }

            let takenCourts = []
            //compare the current slots to the taken slots
            const conflictSlots = takenSlots.filter(timeSlot => timeSlot.date === bookingDay && timeSlot.month === (bookingMonth + 1) && timeSlot.year === bookingYear && slotTimes.includes(timeSlot.time))

            for (let conflict of conflictSlots) {
                if (!takenCourts.includes(conflict.court)) {
                    takenCourts.push(conflict.court)
                }
            }

            // now set courts that are not taken
            let currCourts = [];

            for (let court of allCourts) {
                if (!takenCourts.includes(court.value)) {
                    currCourts.push(court)
                }
            }

            setAvailableCourts(currCourts);
        }

        sortTakenCourts();
    }, [takenSlots, bookingDay, bookingMonth, bookingYear, currStart, bookingOpt, currDuration, bookingCourts]);

    const weeklyBooking = async (e) => {
        e.preventDefault();

        if (bookingCourts.length === 0) {
            alert('Please select at least one court');
            console.log('Please select at least one court');
            return;
        }

        let startDate = new Date(e.target.elements.start_year.value, months.indexOf(e.target.elements.start_month.value), e.target.elements.start_date.value);
        const endDate = new Date(e.target.elements.end_year.value, months.indexOf(e.target.elements.end_month.value), e.target.elements.end_date.value);

        if (startDate >= endDate) {
            alert('Start date cannot be after end date');
            console.log('Start date cannot be after end date');
            return;
        }

        if (startDate.getDay() !== endDate.getDay()) {
            alert('Start and end date must be on the same day');
            console.log('Start and end date must be on the same day');
            return;
        }

        console.log(startDate);
        console.log(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7).getDay());

        const newBookings = [];

        while (startDate <= endDate) {
            console.log(startDate);
            const currBookingData = {
                name: e.target.elements.name.value,
                email: e.target.elements.contact.value === 'email' ? e.target.elements.contact_info.value : "",
                number: e.target.elements.contact.value === 'number' ? e.target.elements.contact_info.value : "",
                date_num: startDate.getDate(),
                month_num: startDate.getMonth() + 1,
                year_num: startDate.getFullYear(),
                time_num: Number(e.target.elements.start_time.value),
                duration: Number(e.target.elements.duration.value),
                type: 'direct',
                status: 'active',
                notes: e.target.elements.notes.value,
                court_nums: bookingCourts.map(court => court.value)
            }

            newBookings.push(currBookingData);

            startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7);
        }

        console.log(newBookings);

        // Check for conflicts in the new bookings
        for (let booking of newBookings) {
            const slots = [];

            const startTime = booking.time_num;
            const duration = booking.duration;

            const sections = duration / 0.5;

            for (let i = 0; i < sections; i++) {
                for (let court of booking.court_nums) {
                    slots.push(
                        {
                            date: booking.date_num,
                            month: booking.month_num,
                            year: booking.year_num,
                            time: startTime + (i * 0.5),
                            status: booking.status,
                            court: court,
                        }
                    )
                }
            }

            const valid = checkConflicts(slots);

            if (!valid) {
                alert('Booking conflicts with another booking: ' + booking.date_num + " " + booking.month_num + " " + booking.year_num + " " + booking.time_num + " " + booking.duration + " " + booking.court_nums);
                console.log('Booking conflicts with another booking: ' + booking.date_num + " " + booking.month_num + " " + booking.year_num + " " + booking.time_num + " " + booking.duration + " " + booking.court_nums);
                return;
            }
        }

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/bookings/add', {
                "booking_info": JSON.stringify({
                    bookings: newBookings,
                    token: auth.token
                })
            });

            if (response.data.status !== 200) {
                alert('Failed to book');
            }

            alert('Booking Successful');
            getBookings();
        } catch (error) {
            console.error(error);
        }

        handleResetBookingData();
    }

    useEffect(() => {
        if (localStorage.getItem('hymus_admin')) {
            setAuth(JSON.parse(localStorage.getItem('hymus_admin')))
        }
    }, [])

    const handleResetBookingData = () => {
        document.getElementById('booking-form').reset();
        setBookingCourts([]);
        setBookingDay(currDate.getDate());
        setBookingMonth(currDate.getMonth());
        setBookingYear(currDate.getFullYear());
        setCurrStart(6);
        setCurrDuration(0.5)
    }

    /**
     * Method to handle the duration of the booking
     * The logic is as follows:
     * 1. Get the hour count based on the start time and the current start time
     * 2. Loop through the hour count and push the hours to the hours array
     * 3. Return the hours array
     * 
     * @returns {array} - The hours array
     */
    const handleDuration = () => {
        let hours = []

        const hourCount = startTimes.length - startTimes.findIndex(time => time.timeNum === currStart);

        for (let i = 1; i <= hourCount; i++) {
            hours.push(
                <option value={durations[i - 1]}>{durations[i - 1] + " Hours"}</option>
            )
        }

        return hours;
    }

    /**
     * Method to check for conflicts in the new slots
     * The logic is as follows:
     * 1. Loop through the new slots
     * 2. Check if the slot is already taken
     * 3. If the slot is taken, return false
     * 4. If the slot is not taken, return true
     * 
     * @param {*} newSlots 
     * @returns {boolean} - True if there are no conflicts, false otherwise
     */
    const checkConflicts = (newSlots) => {
        let isValid = true;
        console.log('here')

        for (let slot of newSlots) {
            const conflict = takenSlots.find(takenSlot => takenSlot.date === slot.date && takenSlot.month === slot.month && takenSlot.year === slot.year && takenSlot.time === slot.time && takenSlot.court === slot.court);
            console.log(conflict)
            if (conflict !== undefined) {
                isValid = false;
                break;
            }
        }

        return isValid;
    }

    /**
     * Login function
     * @param {Event} e - The event object that takes the login form inputs
     */
    const login = async (e) => {
        console.log('login');
        e.preventDefault();

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/admin/login', {
                user_info: JSON.stringify({
                    username: e.target.elements.username.value,
                    password: e.target.elements.password.value
                })
            })

            if (response.data.status !== 200) {
                alert("Login failed. Please try again.");
                console.log(response.data.message);
                return
            }

            setAuth(response.data.admin);
            localStorage.setItem('hymus_admin', JSON.stringify(response.data.admin));
            alert('Login successful!');
            e.target.reset();
        } catch (error) {
            e.target.reset();
            alert('An error occurred. Please try again.');
            console.error(error);
        }

    }

    /**
    * Method to get the bookings from the server
    * The logic is as follows:
    * 1. Try to get the bookings from the server
    * 2. If the response is successful, set the bookings to the state
    * 3. If the response is not successful, alert the user that the bookings failed
    * 4. If there is an error, alert the user that the bookings failed
    */
    const getBookings = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/bookings/get-all',
                {
                    "booking_info": JSON.stringify({
                        token: auth.token
                    }),
                }
            );

            if (response.data.status === 200) {
                setBookings(response.data.bookings);
                return
            }

            alert('Failed to get bookings');
            console.log('Failed to get bookings');
        } catch (error) {
            console.log(error);
        }
    }

    /**
         * Method to handle the single booking
         * The logic is as follows:
         * 1. Prevent the default form submission
         * 2. Check if the booking courts are empty
         * 3. If the booking courts are empty, alert the user to select at least one court
         * 4. Return if the booking courts are empty
         * 5. Check if the email is valid
         * 6. Check if the phone number is valid
         * 7. Create the booking data object
         * 8. Check for conflicts in the new slots
         * 9. If there are no conflicts, post the booking data to the server
         * 10. If the response is successful, alert the user that the booking was successful
         * 11. If there are conflicts, alert the user that there is a conflict with another booking
         * 12. If there is an error, alert the user that the booking failed
         * 13. Get the bookings from the server to get the updated list
         * 
         * @param {*} e 
         * @returns 
         */
    const singleBooking = async (e) => {
        e.preventDefault();

        if (bookingCourts.length === 0) {
            alert('Please select at least one court');
            console.log('Please select at least one court');
            return;
        }

        // const numberRegex = /^\d{3}-\d{3}-\d{4}$/;

        // if (!numberRegex.test(e.target.elements["num"].value)) {
        //     alert('Please enter a valid phone number');
        // }

        const bookingData = {
            name: e.target.elements.name.value,
            email: e.target.elements.contact.value === 'email' ? e.target.elements.contact_info.value : "",
            number: e.target.elements.contact.value === 'number' ? e.target.elements.contact_info.value : "",
            date_num: Number(e.target.elements.date.value),
            month_num: months.indexOf(e.target.elements.month.value) + 1,
            year_num: Number(e.target.elements.year.value),
            time_num: Number(e.target.elements['start-time'].value),
            duration: Number(e.target.elements['duration'].value),
            type: 'direct',
            status: 'active',
            notes: e.target.elements.notes.value,
            court_nums: bookingCourts.map(court => court.value)
        }

        try {
            const slots = [];

            const startTime = Number(e.target.elements['start-time'].value);
            const duration = Number(e.target.elements['duration'].value);

            const sections = duration / 0.5;

            for (let i = 0; i < sections; i++) {
                for (let court of bookingData.court_nums) {
                    slots.push(
                        {
                            date: bookingData.date_num,
                            month: bookingData.month_num,
                            year: bookingData.year_num,
                            time: startTime + (i * 0.5),
                            status: bookingData.status,
                            court: court,
                            name: bookingData.name,
                            email: bookingData.email
                        }
                    )
                }
            }

            const valid = checkConflicts(slots);

            if (valid) {
                const response = await axios.post(process.env.REACT_APP_API_URL + '/bookings/add', {
                    "booking_info": JSON.stringify({
                        bookings: [bookingData],
                        token: auth.token
                    })
                });

                if (response.data.status !== 200) {
                    alert('Failed to book');
                    console.log('Failed to book');
                }

                alert('Booking Successful');
                console.log('Booking Successful');
                getBookings();
            } else {
                alert('Booking conflicts with another booking');
            }
        } catch (error) {
            alert('Failed to book');
            console.log('Failed to book');
            console.error(error);
        }

        handleResetBookingData();
    }

    /**
     * Method to handle the booking options
     * The logic is as follows:
     * 1. Get the default date
     * 2. If the booking option is 0, return the single booking form
     * 3. If the booking option is 1, return the weekly booking form
     * 
     * @returns {JSX} - The booking form
     */
    const handleBookingOpts = () => {
        const defaultDate = new Date();

        if (bookingOpt === 0) {
            return (
                <div className='admin-booking-form'>
                    <form id='booking-form' onSubmit={singleBooking}>
                        <div className='admin-form-group'>
                            <label className='admin-label'>Name:</label>
                            <input className='admin-input' required placeholder='John Doe' name='name' type='text' />
                        </div>

                        <div className='admin-form-group'>
                            <select className='admin-select' name='contact'>
                                <option value='number'>Number</option>
                                <option value='email'>Email</option>
                            </select>
                            <input className='admin-input' name='contact_info' type='text' />
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>Date:</label>
                            <select className='admin-select' name='date' onChange={e => setBookingDay(Number(e.target.value))}>
                                {(days[bookingMonth])?.map((day, index) => <option key={index} selected={currDate.getDate() === day} value={day}>{day + " (" + dayNames[(new Date(currYear, currMonth, day)).getDay()] + ")"}</option>)}
                            </select>

                            <label className='admin-label'>Month:</label>
                            <select className='admin-select' name='month' onChange={(e) => setBookingMonth(monthNames.indexOf(e.target.value))} >
                                {monthNames.map((month, index) => <option key={index} selected={currDate.getMonth() === index} value={month}>{month}</option>)}
                            </select>

                            <label className='admin-label'>Year:</label>
                            <select className='admin-select' name='year' onChange={(e) => setBookingYear(e.target.value)} >
                                {Array.from({ length: 10 }, (_, i) => i).map((year, index) => <option key={index} selected={currDate.getFullYear() === defaultDate.getFullYear() + year} value={defaultDate.getFullYear() + year}>{defaultDate.getFullYear() + year}</option>)}
                            </select>
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>Start Time:</label>
                            <select className='admin-select' name='start-time' onChange={e => setCurrStart(Number(e.target.value))}>
                                {
                                    startTimes.map((time, index) => <option key={index} value={time.timeNum}>{time.timeStr}</option>)
                                }
                            </select>

                            <label className='admin-label'>Duration:</label>
                            <select className='admin-select' name='duration' onChange={e => setCurrDuration(Number(e.target.value))}>
                                {handleDuration()}
                            </select>
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>Courts:</label>
                            <Select
                                value={bookingCourts}
                                onChange={(value) => setBookingCourts(value)}
                                styles={{
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        display: 'flex',
                                        // flexWrap: 'nowrap',
                                        overflowX: 'auto',
                                        overflowY: 'hidden',
                                        maxWidth: '85%',
                                    }),
                                    multiValue: (provided) => ({
                                        ...provided,
                                        flexShrink: 0,
                                    }),
                                }}
                                isMulti
                                options={availableCourts}
                            />
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>Notes:</label>
                            <textarea className='admin-textarea' name='notes'></textarea>
                        </div>

                        <div className='admin-booking-form-actns'>
                            <button className='admin-button' type='button' onClick={() => handleResetBookingData()}>Reset</button>
                            <button className='admin-button' type='submit'>Confirm</button>
                        </div>
                    </form>
                </div>
            )
        } else if (bookingOpt === 1) {
            return (
                <div className='admin-booking-form'>
                    <form id='booking-form' onSubmit={weeklyBooking}>
                        <div className='admin-form-group'>
                            <label className='admin-label'>Name:</label>
                            <input className='admin-input' name='name' type='text' />
                        </div>

                        <div className='admin-form-group'>
                            <select className='admin-select' name='contact'>
                                <option value='number'>Number</option>
                                <option value='email'>Email</option>
                            </select>
                            <input className='admin-input' name='contact_info' type='text' />
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>Start Date:</label>
                            <select className='admin-select' name='start_date'>
                                {(days[bookingMonth])?.map((day, index) => <option key={index} selected={currDate.getDate() === day} value={day}>{day + " (" + dayNames[(new Date(bookingYear, bookingMonth, day)).getDay()] + ")"}</option>)}
                            </select>

                            <label className='admin-label'>Month:</label>
                            <select className='admin-select' name='start_month' onChange={(e) => setBookingMonth(monthNames.indexOf(e.target.value))} >
                                {monthNames.map((month, index) => <option key={index} selected={currDate.getMonth() === index} value={month}>{month}</option>)}
                            </select>

                            <label className='admin-label'>Year:</label>
                            <select className='admin-select' name='start_year' onChange={(e) => setBookingYear(e.target.value)} >
                                {Array.from({ length: 10 }, (_, i) => i).map((year, index) => <option key={index} selected={currDate.getFullYear() === defaultDate.getFullYear() + year} value={defaultDate.getFullYear() + year}>{defaultDate.getFullYear() + year}</option>)}
                            </select>
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>End Date:</label>
                            <select className='admin-select' name='end_date'>
                                {(days[endBookingMonth])?.map((day, index) => <option key={index} selected={currDate.getDate() === day} value={day}>{day + " (" + dayNames[(new Date(endBookingYear, endBookingMonth, day)).getDay()] + ")"}</option>)}
                            </select>

                            <label className='admin-label'>Month:</label>
                            <select className='admin-select' name='end_month' onChange={(e) => setEndBookingMonth(monthNames.indexOf(e.target.value))} >
                                {monthNames.map((month, index) => <option key={index} selected={currDate.getMonth() === index} value={month}>{month}</option>)}
                            </select>

                            <label className='admin-label'>Year:</label>
                            <select className='admin-select' name='end_year' onChange={(e) => setEndBookingYear(e.target.value)} >
                                {Array.from({ length: 10 }, (_, i) => i).map((year, index) => <option key={index} selected={currDate.getFullYear() === defaultDate.getFullYear() + year} value={defaultDate.getFullYear() + year}>{defaultDate.getFullYear() + year}</option>)}
                            </select>
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>Start Time:</label>
                            <select className='admin-select' name='start_time' onChange={e => setCurrStart(Number(e.target.value))}>
                                {
                                    startTimes.map((time, index) => <option key={index} value={time.timeNum}>{time.timeStr}</option>)
                                }
                            </select>

                            <label className='admin-label'>Duration:</label>
                            <select className='admin-select' name='duration' onChange={e => setCurrDuration(Number(e.target.value))}>
                                {handleDuration()}
                            </select>
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>Courts:</label>
                            <Select
                                value={bookingCourts}
                                onChange={(value) => setBookingCourts(value)}
                                styles={{
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        display: 'flex',
                                        // flexWrap: 'nowrap',
                                        overflowX: 'auto',
                                        overflowY: 'hidden',
                                        maxWidth: '85%',
                                    }),
                                    multiValue: (provided) => ({
                                        ...provided,
                                        flexShrink: 0,
                                    }),
                                }}
                                isMulti
                                options={[{ value: 1, label: "Court 1" }, { value: 2, label: "Court 2" }, { value: 3, label: "Court 3" }, { value: 4, label: "Court 4" }, { value: 5, label: "Court 5" }, { value: 6, label: "Court 6" }, { value: 7, label: "Court 7" }, { value: 8, label: "Court 8" }, { value: 9, label: "Court 9" }, { value: 10, label: "Court 10" }, { value: 11, label: "Court 11" }, { value: 12, label: "Court 12" }]}
                            />
                        </div>

                        <div className='admin-form-group'>
                            <label className='admin-label'>Notes:</label>
                            <textarea className='admin-textarea' name='notes'></textarea>
                        </div>

                        <div className='admin-booking-form-actns'>
                            <button className='admin-button' type='button' onClick={() => handleResetBookingData()}>Reset</button>
                            <button className='admin-button' type='submit'>Confirm</button>
                        </div>
                    </form>
                </div>
            )
        }
    }

    useEffect(() => {
        if (!auth.token) {
            return;
        }

        getBookings();
    }, [auth]);

    if (auth.token) {
        return (
            <div className='admin-page'>
                <div className='admin-booking-type'>
                    {/* <select className='admin-select' onChange={(e) => setBookingOpt(Number(e.target.value))}>
                        <option value={0}>One Time</option>
                        <option value={1}>Weekly</option>
                    </select> */}
                    <h1>One Time Booking</h1>
                </div>
                {handleBookingOpts()}
            </div>
        );
    } else {
        return (
            <div className='admin-login-page'>
                <div className='admin-login-console'>
                    <div className='admin-console-header'>
                        <h1>Admin Login</h1>
                    </div>
                    <form className='admin-console-form' onSubmit={login}>
                        <label className='admin-console-label'>Username:</label>
                        <input className='admin-console-input' type='text' name='username' />
                        <label className='admin-console-label'>Password:</label>
                        <input className='admin-console-input' type='password' name='password' />
                        <button className='admin-console-button' type='submit'>Login</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Admin;