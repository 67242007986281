import React from 'react';
import './login.css';
import logo from "../../assets/images/logo.png"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login({ setAuth }) {
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/athletes/login', {
                user_info: JSON.stringify({
                    email: e.target.elements['email'].value,
                    password: e.target.elements['pass'].value
                })
            });

            if (response.data.status === 200) {
                alert('Login successful!');
                sessionStorage.setItem('hymus_athlete', JSON.stringify(response.data.athlete));
                setAuth(response.data.athlete);
                navigate('/');
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert('An error occurred. Please try again.');
            console.error(error);
        }
    }

return (
    <div className='login-page'>
        <div className='login-console'>
            <div className='login-logo'>
                <img src={logo} />
            </div>
            <div className='login-title'>
                <h1>Welcome Back!</h1>
            </div>
            <div className='login-form'>
                <form onSubmit={handleLogin}>
                    <input name='email' type='text' placeholder='Email' />
                    <input name='pass' type='password' placeholder='Password' />
                    <button type='submit'>Login</button>
                </form>
            </div>
            <div className='login-footer'>
                <p>Not registered yet? <a href='/register'>Click Here!</a></p>
                <p>Or</p>
                <p><a href='/'>Return Home</a></p>
            </div>
        </div>
    </div>
);
}

export default Login;